import React from 'react';
import TownSalesList from '../components/TownSalesList';

function MineralWells() {
  return (
    <div>
      <h1>Upcoming Yard Sales in Mineral Wells, TX</h1>
      <TownSalesList townId="mineral-wells" />
    </div>
  );
}

export default MineralWells;
