

function Contact() {
  

  return (
   <div>
    <p class="white">Want to list your yard sale?</p>
    <p className="white">Don't see your city or area? Don't worry we can add it so long as it is within Texas 🤠</p>
    <p class="white">Email us at: <span class='aqua'>contact@txyardsales.com</span></p>
    <p className='white'>Please provide the following info:</p>
    <ul className='white-list'>
      <li class='lime'>-Your Name</li>
      <li class='lime'>-Date(s) of the yard sale</li>
      <li class='lime'>-The address (location) of the yard sale</li>
      <li class='lime'>-Time it will begin & end</li>
      <li class='lime'>-Brief list of items that will be available at the yard sale</li>
    </ul>
    <p className="white">
        Follow us on Facebook:{" "}
        <a
          href="https://www.facebook.com/share/sbEnpCMGeBFp6wyM/?mibextid=LQQJ4d" // replace with your Facebook URL
          target="_blank"
          rel="noopener noreferrer"
          className="aqua"
        >
          TX Yard Sales
        </a>
      </p>
   </div>
  );
}

export default Contact;
