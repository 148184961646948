import React from 'react';
import { useNavigate } from 'react-router-dom';
import { townsData } from '../data/townsData';

function TownDropdown() {
  const navigate = useNavigate();

  const handleTownChange = (event) => {
    navigate(`/sales/${event.target.value}`);
  };

  return (
    <select onChange={handleTownChange}>
      {townsData.map((town) => (
        <option className='big' key={town.id} value={town.id}>
          {town.name}
        </option>
      ))}
    </select>
  );
}

export default TownDropdown;
