import React from 'react';
import TownSalesList from '../components/TownSalesList';

function Weatherford() {
  return (
    <div>
      <h1>Upcoming Yard Sales in Weatherford, TX</h1>
      <TownSalesList townId="weatherford" />
    </div>
  );
}

export default Weatherford;
