export const salesData = {
  'mineral-wells': [
    { id: 2, address: '116 NW 5th Ave', date: '2024-11-17', start: 'morning', items: 'Clothing, Kitchen items, instruments, antlers, jewelry, & more.', message: 'FRI & SAT Nov 15th & 16th, 2024. If it is rainy there is an inside space' },
    
    
  ],
  'weatherford': [
    
  ],
  'fort-worth': [],
  'azle': [
    { id: 1, address: '11875 Farm to Market Rd 730 N, Azle, TX 76020', date: '2025-11-04', start: '7:30am - 4pm', items: 'tools, antiques, & crafts', message: 'Swap Meet at the VFW Open every: Friday, Saturday, & Sunday (weather permitting). If you`d like to sell at the Swap Meet it is $20 for the whole 3 days & that money goes to the VFW (Veterans of Foreign Wars).' }
  ],
};
