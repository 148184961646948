import React from 'react';
import TownSalesList from '../components/TownSalesList';

function Azle() {
  return (
    <div>
      <h1>Upcoming Yard Sales Azle, TX</h1>
      <TownSalesList townId="azle" />
    </div>
  );
}

export default Azle;
