import React from 'react';
import TownSalesList from '../components/TownSalesList';

function FortWorth() {
  return (
    <div>
      <h1>Upcoming Yard Sales in Fort Worth, TX</h1>
      <TownSalesList townId="fort-worth" />
    </div>
  );
}

export default FortWorth;
