import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import MineralWells from './pages/MineralWells';
import Weatherford from './pages/Weatherford';
import FortWorth from './pages/FortWorth';
import Azle from './pages/Azle';
import Contact from './pages/Contact';
import TownDropdown from './components/TownDropdown';
import TownSalesList from './components/TownSalesList';
import logo from './assets/logo.png';
function App() {
  return (
    <Router>
      <div>
        
        <header>
          <a href="/">

        <img src={logo} alt="tx yard sale logo" className='logo'/>
          </a>
        <h1>TX YARD SALES</h1>
        </header>
        <nav className="navbar">
          <Link to="/contact" className='contact'>Contact to list your yard sale!</Link> {/* Separate clickable contact link */}
        </nav>
        <TownDropdown />
        <Routes>
          <Route path="/" element={<div>
            <h2 className='white'>Upcoming Yard Sales in Mineral Wells</h2>
            <TownSalesList townId="mineral-wells"/>
          </div>}/>
          <Route path="/sales/mineral-wells" element={<MineralWells />} />
          <Route path="/sales/weatherford" element={<Weatherford />} />
          <Route path="/sales/fort-worth" element={<FortWorth />} />
          <Route path="/sales/azle" element={<Azle/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </div>
      <footer className='footer'>Texas Lizard Hospitality LLC | 2024 &copy;</footer>
    </Router>
  );
}

export default App;

