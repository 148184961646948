import React from 'react';
import { salesData } from '../data/salesData';

function TownSalesList({ townId }) {
  const sales = salesData[townId] || [];

  // Get the current date, but reset the time to 00:00 to ensure today’s sales are included
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to midnight to ignore the time component

  // Filter sales for today or later
  const futureSales = sales.filter(sale => new Date(sale.date).setHours(0, 0, 0, 0) >= today);
  const sortedSales = futureSales.sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <div>
      {sortedSales.length ? (
        sortedSales.map((sale) => (
          <div className='sale-item' key={sale.id}>
            <h3 id="address">{sale.address}</h3>
            
            <p><span id="bold">Start Time:</span> {sale.start}</p>
            <p><span id="bold">Items:</span> {sale.items}</p>
            <p><span id="bold">Notes:</span> {sale.message}</p>
          </div>
        ))
      ) : (
        <p className='no'>No yard sales listed for this location. Please check back later.</p>
      )}
    </div>
  );
}

export default TownSalesList;
